html,
body {
  height: 100%;
  scroll-behavior: smooth;
  //  scrollbar-color: rgba(0, 0, 0, .2) transparent; /* scroll thumb and track */
  // scrollbar-gutter: stable;
}

@supports not (-webkit-backdrop-filter: blur(1px)) {
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .2);
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, .2);
  }
}

body {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--scp-background-color);
  user-select: none;
  color: var(--scp-theme-color-01);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

b {
  font-weight: bold;
}

iframe {
  border: 0;
}

* {
  font: normal 100% 'Open Sans', sans-serif;
  line-height: 22px;
  font-weight: 600;
  box-sizing: border-box;
  text-size-adjust: 100%;
  touch-action: manipulation;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

p,
button,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
  border: 0;
}

h2 {
  font-size: 16px;
  font-weight: 600;
}

button:focus,
input:focus {
  outline: 0;
}

:host {
  display: block;
}

.scp-button {
  display: block;
  line-height: 50px;
  height: 50px;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  &.scp-button-last {
    margin-bottom: 0;
  }
}

button {
  cursor: pointer;
}

.scp-button:disabled {
  opacity: 0.65;
  cursor: auto;
}

.scp-button.scp-type-1 {
  color: var(--scp-button-type-1-color);
  background-color: var(--scp-button-type-1-background);
  box-shadow: var(--scp-button-shadow);
}

.scp-button.scp-type-2 {
  color: var(--scp-button-type-2-color);
  background-color: var(--scp-button-type-2-background);
  line-height: 60px;
  height: 60px;
  border: solid 1px #72878e;
}

.scp-button.scp-type-3 {
  color: var(--scp-button-type-3-color);
  background-color: var(--scp-button-type-3-background);
  box-shadow: var(--scp-button-shadow);
}

.scp-button-prefix {
  padding-left: 30px;
  padding-right: 30px;
}

.scp-button-prefix:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 50px;
  margin: auto;
  display: block;
}

.scp-button.scp-type-2.scp-button-prefix:before {
  height: 60px;
}

.scp-input-block {
  display: flex;
}

.scp-input {
  width: 100%;
  height: 50px;
  // line-height: 46px;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  background-repeat: no-repeat;
  border: 1px solid var(--scp-input-border-color);
  color: var(--scp-input-color);
  background-color: var(--scp-input-background-color);
  caret-color: var(--scp-input-color);

  &.scp-input-right {
    text-align: right;
  }

  &.scp-input-info {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.type-4 {
    background-color: rgba(239, 120, 28, 0.10);
    border: 1px solid var(--scp-theme-color-03);
    color: var(--scp-theme-color-03);
  }
}

::placeholder {
  color: var(--scp-input-placeholder-color);
}

.scp-input.scp-input-invalid,
input.ng-dirty.ng-invalid,
select.ng-touched.ng-invalid {
  border-color: var(--scp-input-error-color);
  color: var(--scp-input-error-color);
  background-color: var(--scp-input-error-background);
}

.scp-label {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: var(--scp-label-color);
  margin: 15px 0 6px 0;

  a {
    color: #60a100;
    font-weight: 600;
  }
}

.scp-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  width: fit-content;
  font-weight: 400;
}

/* Hide the browser's default checkbox */
.scp-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.scp-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  border: solid 1px #cad9e2;
}

/* Create the checkmark/indicator (hidden when not checked) */
.scp-checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.scp-checkbox-container input:checked ~ .scp-checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.scp-checkbox-container .scp-checkbox-checkmark:after {
  left: 6px;
  top: 0;
  width: 8px;
  height: 15px;
  border: solid #60a100;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.scp-select {
  height: 50px;
  line-height: 46px;
  padding: 0 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  font-size: 18px;
  border: 1px solid var(--scp-input-border-color);
  color: var(--scp-input-color);
  background-color: #fafbfc;
  appearance: none;
}

.scp-select-container {
  position: relative;

  .scp-select-placeholder {
    color: var(--scp-input-placeholder-color);
    position: absolute;
    display: flex;
    top: 0;
    left: 10px;
    right: 0;
    bottom: 0;
    align-items: center;
    pointer-events: none;
  }
}

.scp-select-container:after {
  content: ' ';
  position: absolute;
  background: url(/assets/images/svg/back.svg) no-repeat;
  height: 20px;
  width: 20px;
  color: #434b67;
  pointer-events: none;
  top: 50%;
  right: 6px;
  transform: translate(0%, -70%) rotate(270deg);
}

.scp-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  padding: 0 16px 16px;
  flex: 1 0 auto;

  &.center {
    flex: 1 0 auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.scp-page-menu {
  display: grid;
  padding-top: 8px;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  box-sizing: border-box;
}

@media (max-width: 359px) {
  .scp-page-menu {
    grid-row-gap: 8px;
    grid-column-gap: 8px;
  }
}

.scp-snackbar {
  max-width: 100vw !important;
  min-width: unset;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
  background: transparent;
  padding: 8px !important;
  box-shadow: none;
  margin-top: 0 !important;

  @media (min-width: 961px) {
    margin: 0 0 0 290px !important;
  }
}

scp-dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

@media (min-width: 961px) {
  body {
    .mat-sidenav-content {
      margin-left: 290px !important;
    }
  }
}

.scp-dialog .mat-dialog-container {
  padding: 10px;
}

.scp-dialog-component {
  max-height: calc(100vh - 120px);
}

@media (min-width: 320px) and (max-width: 480px) {
  .scp-page {
    padding: 0 12px 12px 12px;
  }

  .g-nav-next,
  .g-nav-prev {
    top: 81% !important;
  }
}

.phone {
  white-space: nowrap;
  font-weight: 400;
}

.scp-filter-with-border {
  cdk-dialog-container {
    border-radius: 20px;
  }
}

.scp-filter {
  border: 0;
  width: 100%;
  max-width: 530px !important;
  margin: 10px;

  &.scp-filter-with-border {
    max-width: 405px !important;
  }

  &.scp-filter-with-border.long {
    max-width: 500px !important;
  }

  .mat-date-range-input-start-wrapper {
    overflow: visible !important;
    max-width: unset !important;
  }
}

.scp-filter cdk-dialog-container {
  padding: 0;
  width: 100% !important;
  height: unset;
}

.scp-backdrop {
  background-color: var(--scp-theme-color-04-variable-04);
}


.scp-filter-without-width {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 10px;

  cdk-dialog-container {
    border-radius: 20px;
    padding: 0;
    width: 100% !important;
    display: flex;
    min-width: 100%;

    > * {
      display: block;
      width: 100%;
    }
  }
}

@media (min-width: 567px) and (max-width: 823px) and (orientation: landscape) {
  .scp-filter, .scp-filter-without-width {
    max-width: 90% !important;
  }
}

@media (max-width: 414px) and (orientation: portrait) {
  .scp-filter, .scp-filter-without-width {
    max-width: 100% !important;
    width: 100%;
    margin: 10px;
  }
}

@media (min-width: 961px) {
  .scp-filter, {
    margin-left: 300px;
  }

  .scp-filter-without-width {
    margin-left: 300px;
  }
}

select {
  cursor: pointer;
}

.scp-bonus-terms {
  ol {
    list-style: none;
    counter-reset: li;

    > li::before {
      content: counter(li) '.';
      color: var(--scp-theme-color-12);
      display: inline-block;
      width: 36px;
      min-width: 36px;
      margin-left: -40px;
      font-weight: bold;
    }

    > li {
      line-height: 28px;
      font-weight: normal;
      padding-bottom: 20px;
      text-align: justify;
      display: inline-block !important;

      ul {
        padding-top: 20px;
      }
    }

    > li {
      counter-increment: li;
    }

    > li:last-of-type {
      padding-bottom: 0;
    }

    li {
      line-height: 28px;
      font-weight: normal;
      padding-bottom: 20px;
    }
  }
}

.drag-scroll-content {
  height: calc(100% + 15px) !important;
}

mat-datepicker-content {
  .mat-datepicker-close-button {
    display: none;
  }
}

.scp-doc {
  a {
    color: var(--scp-theme-color-02);
    line-height: 22px;
  }

  .ltx_font_serif, .ltx_font_sansserif, .ltx_font_sansserif, .ltx_font_typewriter, .ltx_font_mathcaligraphic {
    font-family: 'Open Sans' !important;
  }

  .ltx_title_section {
    font-size: 18px !important;
  }

  .ltx_title_subsection {
    font-size: 16px !important;
  }

  .ltx_tag_subsection, .ltx_tag_subsubsection, .ltx_tag_section {
    font-weight: bold;
  }

  .ltx_role_newpage {
    height: 0 !important;
  }

  .ltx_p {
    margin-bottom: 10px;
  }

  .ltx_tabular {
    max-width: 95%;
  }

  .ltx_td {
    white-space: normal;
  }

  .ltx_section:first-of-type {
    margin-top: 0 !important;
  }

  .ltx_itemize,
  .ltx_enumerate {
    margin-left: 0 !important;
    padding-inline-start: 20px;
  }

  p {
    font-weight: normal !important;
  }
}

.footer {
  align-items: center;
  justify-content: center;

  p {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    font-weight: 600;
    color: var(--scp-theme-color-01);
  }

  .logos {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.scp-expand-content {
  font-weight: normal;
  padding-top: 5px;

  ul {
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    padding-left: 25px;

    li {
      font-weight: normal;
      margin-bottom: 5px;
      text-align: justify;
    }

    li:last-of-type {
      margin-bottom: 0;
    }

    li::before {
      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: var(--scp-theme-color-03); /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }

  a {
    color: var(--scp-theme-color-03);
  }
}

.cdk-visually-hidden {
  height: 0 !important;
}

.remove-shadow {
  box-shadow: none !important;

  &.mat-menu-above {
    margin-bottom: -1px;
  }

  &.mat-menu-below {
    margin-top: -1px;
  }
}

.mat-autocomplete-panel {
  border-radius: 15px !important;
}

.mat-autocomplete-loading, .mat-autocomplete-no-results, .mat-autocomplete-result {
  opacity: 1 !important;

  div {
    color: var(--scp-theme-color-01) !important;
  }

  min-height: 48px !important;
  height: auto !important;
  padding: 0 8px !important;

  .mat-option-text {
    white-space: normal;
  }

  .scp-icon {
    height: 30px;
    width: 30px;
    margin: 5px 10px 5px 5px;
    display: flex;

    img {
      display: inline;
      margin: 0 auto;
      height: 100%;

      &.border {
        box-shadow: 0 2px 4px 0 rgb(0 25 30 / 15%);
        background-color: #fff;
        padding: 4px;
        border-radius: 50%;
      }
    }
  }

  .scp-provider:not(.ezugi) {
    filter: invert(41%) sepia(3%) saturate(2988%) hue-rotate(150deg) brightness(98%) contrast(81%);
  }

  .scp-error {
    color: var(--scp-notification-error-color);
  }

  .mat-autocomplete-row {
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
  }

}

.mat-autocomplete-loading {
  img {
    margin-right: 10px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.scp-blog-posts__post__content, .scp-cares-posts__post__content {
  text-align: justify;
}

.scp-blog-posts__post__content,
.scp-blog-post__content,
.scp-cares-posts__post__content,
.scp-cares-post__content {
  font-weight: 400;

  img {
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .image-style-align-center {
    text-align: center;
  }

  .image-style-align-right {
    text-align: right;
  }

  h2,
  h3 {
    color: var(--scp-theme-color-01);
    margin-top: 20px;
  }

  p {
    font-weight: 400;
    margin-bottom: 10px;
  }

  li {
    font-weight: 400;
  }

  a {
    color: var(--scp-theme-color-02);
  }
}

.scp-inbox {
  width: 260px;
}

.scp-notification a {
  color: var(--scp-theme-color-05);
}

.scp-bonus-program-progress-rule {
  > * {
    font-weight: 400 !important;
  }

  p, ul > li {
    font-weight: 400;
    padding-bottom: 10px;
  }
}

.scp-widgets {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 10px;
}

.scp-social {
  margin-top: -5px;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    width: 36px;
    height: 36px;
    margin: 5px;
    justify-content: center;
    align-items: center;
  }
}

.scp-banner-info__content-scrolled {
  a {
    color: var(--scp-theme-color-02);
    font-weight: bold;
  }
}

.scp-download-apps {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px 16px;

  a {
    margin: 8px;
  }
}

.scp-download-app {
  height: 40px;
}

.footer {
  display: flex;
  flex-direction: column;
}

.footer.hide-apps {
  .scp-download-apps {
    display: none !important;
  }
}

.footer, .scp-doc {
  .raw-html-embed {
    float: left;
    width: 100%;
  }
}


.scp-promotion-base__body {
  div, li {
    font-weight: normal;
  }

  ol {
    margin: 0;
    padding-left: 25px;
  }
}

.scp-promotion-free-bets-terms,
.scp-promotion-ad-terms,
.scp-promotion-bonus-program-terms {
  a {
    color: var(--scp-theme-color-02);
  }

  div, p {
    font-weight: normal;
  }

  li {
    font-weight: normal;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.scp-bookmakers-backdrop {
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(18px);
}

.scp-bookmakers-panel {
  max-height: calc(100vh - 40px) !important;
}
